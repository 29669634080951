<template>
  <header @click="onCollapse">
    <h5 class="blog-title">
      <span>{{ index + 1 }}</span>
      {{ item.category }}
    </h5>
    <base-button
      :class="['chevron', { 'chevron--open': isCollapsed }]"
      :disable-hover-effect="true"
      :mobile-full-width="false"
      :full-width="false"
      icon="Chevron_Top"
      size="content"
      color="none"
    />
  </header>
  <transition-group tag="ol" name="fade-down" class="item-wrapper" appear>
    <li
      v-for="post in item.posts"
      v-if="isCollapsed"
      :key="post.id"
      class="item-wrapper__item"
    >
      <nuxt-link class="item-wrapper__item-link" :to="post.path">
        <div>
          <base-button
            class="chevron chevron--open"
            :disable-hover-effect="true"
            icon="Chevron_Left"
            size="content"
            color="none"
          />
          <span class="truncate-2-lines">{{ post.title }}</span>
        </div>
      </nuxt-link>
    </li>
  </transition-group>
</template>

<script setup lang="ts">
// ************* import COMPONENTS ************* //
// ************* import ASSETS ************* //
// ************* import STORES ************* //
interface Props {
  item?: object;
  index?: number;
}
defineProps<Props>();

const isCollapsed = ref(false);

// ************* STORES ************* //
function onCollapse() {
  isCollapsed.value = !isCollapsed.value;
}
</script>

<style lang="scss" scoped>
header {
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-title {
  text-transform: uppercase;
  display: flex;
  font-size: $font-medium;
  align-items: center;
  gap: 1rem;
}

.item-link,
h5 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  span {
    font-family: 'IBM Plex Serif', serif;
  }
}

.item-wrapper {
  background-color: var(--color-secondary-weak-transparent);
  border-radius: $border-default;

  &__item {
    padding: 1rem;
    border-radius: $border-default;

    &:hover {
      color: var(--color-white);
    }

    &-link {
      &:focus {
        box-shadow: none;
      }

      div {
        display: flex;
        align-items: center;
        gap: 1rem;
        transition: all 0.5s ease-in;
      }
    }
  }

  li {
    color: var(--color-accent-light);
    font-size: $font-medium;
    list-style: none;
  }
}

:deep(.icon-container.size--small) {
  width: 1.5rem;
  height: 1.5rem;
}

.chevron {
  transition: transform 0.5s ease;
  width: 2rem;
  //height: 2rem;
  overflow: visible;
  transform-origin: center;

  @include respond(phone) {
    overflow: visible;

    :deep(.icon) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--open {
    transform: rotate(180deg);
  }
}

.router-link-active {
  color: var(--color-white);
}
</style>
