<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content/dist/runtime/types';
import { onMounted, onBeforeUnmount, ref, watch, nextTick } from 'vue';
import { useBlogStore } from '@/stores/blogStore';
import { storeToRefs } from 'pinia';

const allHeadings = ref([]);

const blogStore = useBlogStore();

const activeHeadingId = ref<string | null>(null);

const chapterHeading = ref<HTMLDataListElement[]>();
let deactivateScroll = false;

const activeHeadingIndex = computed(() => {
  return props.headings.findIndex(
    (heading) => heading.props.id === activeHeadingId.value,
  );
});

const activeHeadingTop = computed(() => {
  if (!allHeadings.value.filter((e) => !!e).length) {
    return 0;
  } else {
    return chapterHeading.value?.at(activeHeadingIndex.value)?.offsetTop;
  }
});

const activeHeadingHeight = computed(() => {
  if (!allHeadings.value.filter((e) => !!e).length) {
    return 0;
  } else {
    return chapterHeading.value
      ?.at(activeHeadingIndex.value)
      ?.getBoundingClientRect().height;
  }
});

interface Props {
  headings: ParsedContent[];
}

const props = defineProps<Props>();
const { blogNodes } = storeToRefs(blogStore);

watch(
  props,
  (props) => {
    if (props?.headings) {
      getElementsById(props.headings);
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

function handleScroll() {
  if (deactivateScroll || !blogNodes.value) return;

  const nodes = Array.from(blogNodes.value).filter((node) => {
    return node.tagName?.startsWith('H');
  });

  const scrollTop = document.documentElement.scrollTop;
  let closestHeading = null;
  let closestHeadingDistance = null;

  for (let i = 0; i < nodes.length; i++) {
    const headingElement = nodes[i];
    const distance = Math.abs(headingElement.offsetTop - scrollTop);

    if (closestHeadingDistance === null || distance < closestHeadingDistance) {
      closestHeading = headingElement;
      closestHeadingDistance = distance;
    }
  }

  if (closestHeading) {
    activeHeadingId.value = closestHeading.id;
    // Remove active class from all headings
    allHeadings.value.forEach((heading) => {
      if (heading) heading.classList.remove('active');
    });
    // Add active class to the closest heading
    closestHeading.classList.add('active');
  }
}

async function getElementsById(headings) {
  allHeadings.value = headings
    .map((heading) => {
      if (!heading) return;
      return document.getElementById(heading.props.id);
    })
    .filter((e) => !!e);
  activeHeadingId.value = allHeadings.value[0]?.id || null;
}

async function handleScopedNavigation(id: string) {
  deactivateScroll = true;
  const node = document.getElementById(id);
  if (!node) {
    deactivateScroll = false;
  }

  // Remove active class from all headings
  allHeadings.value.forEach((heading) => {
    if (heading) heading.classList.remove('active');
  });

  // Add active class to the clicked heading
  node?.classList.add('active');

  activeHeadingId.value = id;
  new Promise((resolve) => {
    node?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setTimeout(() => {
      resolve();
    }, 1000);
  }).then(() => {
    deactivateScroll = false;
  });
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <h6 class="chapters mb-2">Chapters</h6>
  <ul class="list">
    <li
      v-for="heading in headings"
      :class="[{ active: heading.props.id === activeHeadingId }]"
      ref="chapterHeading"
      @click="handleScopedNavigation(heading.props.id)"
      :key="heading._id"
    >
      {{ heading.children[0].value }}
    </li>
    <li
      class="moving-div"
      :style="{
        top: `${activeHeadingTop}px`,
        height: `${activeHeadingHeight}px`,
      }"
    />
  </ul>
</template>

<style scoped lang="scss">
.chapters {
  font-size: $font-subtitle-1;
}

.list {
  font-size: $font-subtitle-2;
  position: relative;

  li {
    margin-left: 1rem;
    margin-bottom: 1rem;
    color: var(--color-black-shade);
    cursor: pointer;
  }

  .active {
    color: var(--color-white);
    border-radius: 1rem;
    cursor: text;
  }
}

.moving-div {
  position: absolute;
  left: -2rem;
  width: 4px;
  height: 30px;
  background-color: #fff;
  transition:
    top 300ms ease,
    height 300ms ease;
}
</style>
