<template>
  <nav class="nav__list">
    <transition-group tag="ol" name="fade-up" appear>
      <li
        v-for="(blog, index) in categories"
        v-if="categories.length"
        :key="blog.id"
        class="nav__list-item"
      >
        <nav-item :item="blog" :index="index" />
      </li>
    </transition-group>
  </nav>
</template>
<script setup lang="ts">
import { watchEffect, reactive } from 'vue';
import type { Ref } from 'vue';
import type { ParsedContent } from '@nuxt/content/dist/runtime/types';
import NavItem from '@/components/navigation/NavItem/NavItem.vue';

interface BlogParsedContent extends ParsedContent {
  blog: 'post';
}

const categories = reactive([]);

const { data, pending }: { data: Ref<BlogParsedContent>[] } =
  await useAsyncData('blogContent', () =>
    queryContent<BlogParsedContent>('/').find(),
  );

watchEffect(() => {
  if (data) {
    if (!data.value) return;
    const c = data.value.map((d) => d.category);
    const uniqueCategories = new Set(c);

    uniqueCategories.forEach((category) => {
      if (!category) {
        return;
      }

      const categoryData = data.value.filter(
        (item) => item.category === category,
      );

      const posts = categoryData.map((item) => ({
        title: item.title,
        path: item._path,
        id: item._id,
      }));

      const newCategory = {
        category,
        posts,
      };

      categories.push(newCategory);
    });
  }
});
</script>

<style lang="scss" scoped>
.heading-medium {
  font-size: $font-medium;
}

.nav {
  &__list {
    padding-top: $spacing-default;

    &-item {
      background-color: var(--color-secondary-weak-transparent);
      border-radius: $border-default;
      margin-bottom: $spacing-small;
    }
  }
}
</style>
