<script setup lang="ts">
// import BaseSearch from '@/components/search/BaseSearch.vue';
import { ref } from 'vue';

const isTopMenuOpen = ref(false);
</script>

<template>
  <div class="top-container">
    <!--    <transition name="slide-up">-->
    <header
      class="blog-header"
      :class="{ 'blog-header--hidden': !isTopMenuOpen }"
    >
      <nav class="nav">
        <nuxt-link class="nav__link hover-underline" to="/">
          {{ $t('nav.home') }}
        </nuxt-link>
      </nav>
    </header>
    <!--    </transition>-->
    <base-button
      key="button-chevron"
      type="button"
      color="none"
      class="blog-header__btn"
      size="content"
      :disable-hover-effect="true"
      @click="isTopMenuOpen = !isTopMenuOpen"
    >
      <base-icon
        icon="chevron-up"
        :class="[
          'blog-header__btn-icon',
          { 'blog-header__btn-icon--open': isTopMenuOpen },
        ]"
      />
    </base-button>
  </div>
</template>

<style scoped lang="scss">
.top-container {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: fit-content;
  background: var(--color-black);
  box-shadow: 0 1px 10px 0 var(--color-primary-shade-400);

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;

    &__link:link,
    &__link:visited {
      color: var(--color-white);
    }
  }

  .blog-header {
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    padding: 1rem 2rem 0 2rem;
    background-color: var(--color-black);
    transition: all 0.3s ease-in-out;

    &--hidden {
      height: 0;
      visibility: hidden;
      transform: scale(0) translateY(-5rem);
      opacity: 0;
    }

    &__btn {
      background-color: var(--black);
      position: relative;
      left: 50%;
      width: 3rem;
      height: 1.8rem;
      z-index: -1;
      transform: translateX(-50%);
      transform-origin: center;
      &:hover {
        background-color: var(--color-primary-shade-300);
      }

      &-icon {
        width: 100%;
        height: 100%;
        transition: all 250ms ease;

        &:hover {
          color: var(--color-white-shade);
        }

        &--open {
          transform: rotateX(180deg);
        }
      }

      &::after {
        content: '';
        width: 100vw;
        height: 1rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &::before {
        content: '';
        width: 20rem;
        height: 5rem;
        border-radius: 100%;
        position: absolute;
        background: var(--color-black);
        left: 50%;
        top: 50%;
        z-index: -5;
        transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 1px 10px 0 var(--color-primary-shade-400);
      }
    }
  }
}
</style>
