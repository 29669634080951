<template>
  <base-wrapper
    tag="aside"
    :class="['blog-menu', { 'blog-menu--collapsed': state.isCollapsed }]"
  >
    <div class="blog-menu__content-wrapper">
      <transition name="fade-left" appear>
        <div v-if="!state.isCollapsed">
          <div class="blog-menu__logo-wrapper">
            <nuxt-link class="blog-menu__logo-link" to="/">
              <base-logo class="blog-menu__logo" />
            </nuxt-link>
            <h5>LOG</h5>
          </div>
          <base-button
            class="blog-menu__btn"
            size="smallest"
            color="neutral"
            :mobile-full-width="false"
            icon="Chevron_Left"
            @click="$router.go(-1)"
          >
            Go back
          </base-button>
          <nuxt-link to="/blog">
            <h2 class="blog-menu__heading">Contents</h2>
          </nuxt-link>
          <blog-navigation @click="handleNavClick" />
        </div>
      </transition>
      <base-button
        class="chevron"
        :class="{
          'chevron--collapsed': state.isCollapsed,
          'chevron-btn': true,
        }"
        size="small"
        :mobile-full-width="false"
        icon="Chevron_Right"
        @click="toggleSideBar"
      />
    </div>
  </base-wrapper>
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, reactive, watchEffect } from 'vue';
import BlogNavigation from '@/components/blog/BlogNavigation.vue';
import { useBreakpoints } from '@vueuse/core';

const state = reactive({
  isCollapsed: false,
});

function toggleSideBar() {
  state.isCollapsed = !state.isCollapsed;
}

const breakpoints = useBreakpoints({ desktop: 1300 });

watchEffect(() => {
  state.isCollapsed = !breakpoints.greaterOrEqual('desktop').value;
});

function handleNavClick(e: Event) {
  if (breakpoints.greaterOrEqual('desktop').value) return;
  const $menuItem = e.target?.closest('.blog-menu');
  if (!$menuItem) {
    state.isCollapsed = true;
  }
}

onMounted(() => {
  nextTick(() => {
    window?.addEventListener('click', handleNavClick);
  });
});

onBeforeUnmount(() => {
  window?.removeEventListener('click', handleNavClick);
});
</script>

<style scoped lang="scss">
.blog-menu {
  width: 100%;
  padding: 5rem 2rem 3rem 2rem;
  background-color: var(--color-accent);
  transition: background-color 250ms ease;
  position: relative;
  overflow-y: auto;

  @include respond(phone) {
    padding: 5rem 1rem 3rem 1rem;
  }

  @include respond(normal-desktop) {
    box-shadow: 0px 0 10px var(--color-primary-shade-500);
  }

  &__content-wrapper {
    //position: fixed;
  }

  &__logo-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: $spacing-small;
  }

  // Side bar state
  &--collapsed {
    background-color: transparent;
    box-shadow: none;
  }

  &__logo-link {
    display: flex;
    justify-content: center;
    height: auto;
  }

  &__btn {
    margin-bottom: $spacing-small;

    & > :first-child {
      padding: 1.4rem;
    }

    &-toggle.chevron {
      display: inline-block;
      padding: 0;
      width: 5rem;
      height: 5rem;

      :deep(.btn-neutral) {
        display: block;
      }
    }
  }

  .chevron {
    width: fit-content;
    transition: all 250ms ease;
    background: var(--color-black);

    @include respond(tab-port-small) {
      display: flex;
    }

    :deep(.icon) {
      width: 0.7rem;
      height: 1.2rem;
    }

    @include respond(phone) {
      :deep(.icon) {
        width: 0.8rem;
        height: 1.5rem;
      }
    }

    &--collapsed {
      transform: rotateY(180deg);
    }

    &-btn {
      position: fixed;
      bottom: 2rem;
      left: 2rem;
    }
  }

  &__heading {
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 5px;
    font-size: $font-large;
  }

  .wrapper {
    transition: all 0.2s ease-out;
    transform-origin: center;
  }
}
</style>
