<script setup lang="ts">
import { watchEffect, watch, ref, onBeforeUnmount, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useBreakpoints, useDateFormat } from '@vueuse/core';
import BaseChapters from '@/components/blog/BaseChapters.vue';
import UserCard from '@/components/library/user/UserCard.vue';
import type { MarkdownNode } from '@nuxt/content/dist/runtime/types';

const route = useRoute();
const breakpoints = useBreakpoints({ tablet: 800 });

const state = ref({
  headings: [],
  publishedAt: '',
  isCollapsed: false,
});

watchEffect(() => {
  if (breakpoints?.greaterOrEqual('tablet').value) {
    state.value.isCollapsed = false;
  }
});

watch(
  breakpoints.greaterOrEqual('tablet'),
  (b) => {
    if (b) {
      state.value.isCollapsed = false;
    }
  },
  { immediate: true },
);

watch(
  route,
  async ({ path }) => {
    if (path === '/') return;
    const res = await queryContent().where({ _path: path }).findOne();
    const date = useDateFormat(res.date, 'DD-MM-YYYY');
    state.value.publishedAt = date.value;
    extractAllHeading(res.body?.children);
  },
  {
    immediate: true,
  },
);

function extractAllHeading(children: MarkdownNode[] | undefined) {
  if (!children) {
    return;
  }
  state.value.headings = children.filter((child) => child.tag.includes('h'));
}

function handleNavClick(e) {
  if (breakpoints.isGreater('tablet')) return;
  const $menuItem = e.target.closest('.blog-info-wrapper');

  if (!$menuItem) {
    state.value.isCollapsed = true;
  }
}

onMounted(() => {
  nextTick(() => {
    window?.addEventListener('click', handleNavClick);
  });
});

onBeforeUnmount(() => {
  window?.removeEventListener('click', handleNavClick);
});
</script>

<template>
  <base-wrapper
    :class="[
      'blog-info-wrapper',
      { 'blog-info-wrapper--shadow': !state.isCollapsed },
    ]"
    tag="aside"
  >
    <transition name="fade-right" appear>
      <div v-show="!state.isCollapsed">
        <user-card class="mb-4" />
        <base-card class="mb-4">
          <h3 class="title">Published at</h3>
          <p>{{ state.publishedAt }}</p>
        </base-card>
        <client-only>
          <base-chapters :headings="state.headings" />
        </client-only>
      </div>
    </transition>

    <base-button
      class="chevron"
      :class="{
        'chevron--collapsed': state.isCollapsed,
        'chevron-btn': true,
      }"
      size="small"
      :mobile-full-width="false"
      icon="Chevron_Left"
      @click="state.isCollapsed = !state.isCollapsed"
    />
  </base-wrapper>
</template>

<style scoped lang="scss">
.title {
  font-size: $font-subtitle-2;
}

.blog-info-wrapper {
  background-color: var(--color-black);
  max-width: 26rem;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;

  > :first-child {
    padding: 5rem 2rem 2rem;
  }

  &--shadow {
    @include respond(tab-port-small) {
      box-shadow: 0px 0 10px var(--color-primary-shade-500);
    }
  }
}

.chevron {
  transition: all 250ms ease;
  display: none;
  background: var(--color-black);

  @include respond(tab-port-small) {
    display: flex;
  }

  :deep(.icon) {
    width: 0.8rem;
    height: 1.5rem;
  }

  &--collapsed {
    transform: rotateY(180deg);
  }

  &-btn {
    width: fit-content;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
  }
}
</style>
